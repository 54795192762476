


















import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import validator from "validator";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: false,
      default: {},
    },
    email: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root, emit }) {
    const model = reactive({
      email: props.email,
    });

    const state = reactive({
      loading: false,
      error: false as boolean | number,
    });

    const closeDialog = () => {
      emit("close");
    };

    const rules = {
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.participantNotFound")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      const data = {
        email: model.email,
      };

      axiosInstance
        .post(`mail-checklist/${props.item.id}/send`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("layout.misc.testEmail.success"),
          });

          setTimeout(() => {
            emit("close");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { model, state, closeDialog, rules, onSubmit };
  },
});
